import { Vue } from 'vue-property-decorator';
import VueI18n from 'vue-i18n';
// import OttServiceRequestWidget from './components/OttServiceRequestWidget.vue';
import OttHeaderWidget from '@/components/OttHeaderWidget.vue';
import OttFooterWidget from '@/components/OttFooterWidget.vue';
import OttStatusListWidget from '@/components/OttStatusListWidget.vue';
import OttTopButtonWidget from '@/components/OttTopButtonWidget.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {faBars, faMinus, faPlus, faSearch, faTimes} from '@fortawesome/free-solid-svg-icons';

library.add(faBars, faMinus, faPlus, faSearch, faTimes);
Vue.config.productionTip = false;

let pageLocale = 'en';
if (document && document.documentElement && document.documentElement.lang) {
    const htmlLocale = document.documentElement.lang;
    if (typeof htmlLocale !== 'undefined' && htmlLocale !== null
        && htmlLocale.includes('fr')) {
        pageLocale = 'fr';
    }
}

// Configure the i18n strings.
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: pageLocale,
});

// Load the Header widget.
const headerWidget = document.getElementById('ottux-header');
if (headerWidget !== null) {
    new Vue({
        i18n,
        data: {
            language: headerWidget.getAttribute('data-language'),
            extraLinks: headerWidget.getAttribute('data-menu-links'),
            searchAction: headerWidget.getAttribute('data-search-target'),
            skipToContentId: headerWidget.getAttribute('data-skip-to-content-id'),
            searchInputFocusTriggerId: headerWidget.getAttribute('data-search-input-focus-trigger'),
            searchNameAttr: headerWidget.getAttribute('data-search-field-name'),
            languageLink: headerWidget.getAttribute('data-language-link'),
            menuLabel: headerWidget.getAttribute('data-menu-label'),
            homeLinkUrl: headerWidget.getAttribute('data-home-url'),
            homeLinkTitle: headerWidget.getAttribute('data-home-title'),
            searchResultsId: headerWidget.getAttribute('data-search-results-id'),
            links: headerWidget.getAttribute('data-gateways'),
            apiServer: headerWidget.getAttribute('data-api-server'),
            apiPath: headerWidget.getAttribute('data-api-path'),
            apiEndpoint: headerWidget.getAttribute('data-endpoint'),
            apiAlertServer: headerWidget.getAttribute('data-api-alert-server'),
            apiAlertPath: headerWidget.getAttribute('data-api-alert-path'),
            apiAlertEndpoint: headerWidget.getAttribute('data-api-alert-endpoint')
        },
        render: (h) => h(OttHeaderWidget),
    }).$mount(headerWidget);
}

const footerWidget = document.getElementById('ottux-footer');
if (footerWidget !== null) {
    new Vue({
        i18n,
        data: {
            language: footerWidget.getAttribute('data-language'),
            contactMenu: footerWidget.getAttribute('data-footer-contact-menu'),
            aboutMenu: footerWidget.getAttribute('data-footer-about-menu'),
            socialMenu: footerWidget.getAttribute('data-footer-social-menu'),
            feedbackMenu: footerWidget.getAttribute('data-footer-feedback-menu'),
            apiServer: footerWidget.getAttribute('data-api-server'),
            apiPath: footerWidget.getAttribute('data-api-path'),
            apiEndpoint: footerWidget.getAttribute('data-endpoint'),
        },
        render: (h) => h(OttFooterWidget),
    }).$mount(footerWidget);
}

const topButtonWidget = document.getElementById('ottux-topbutton');
if (topButtonWidget !== null) {
    new Vue({
        i18n,
        data: {
            language: topButtonWidget.getAttribute('data-language'),
            target: topButtonWidget.getAttribute('data-target-id'),
            location: topButtonWidget.getAttribute('data-location'),
            zindex: topButtonWidget.getAttribute('data-z-index'),
        },
        render: (h) => h(OttTopButtonWidget),
    }).$mount(topButtonWidget);
}

// Load the status widget.
const statusWidget = document.getElementById('ott-widget-status-block');
if (statusWidget !== null) {
    new Vue({
        i18n,
        data: {
            apiServer: statusWidget.getAttribute('data-api-server'),
            apiPath: statusWidget.getAttribute('data-api-path'),
            apiEndpoint: statusWidget.getAttribute('data-endpoint'),
        },
        render: (h) => h(OttStatusListWidget),
    }).$mount(statusWidget);
}
