












import Vue from 'vue';
import Component from 'vue-class-component';
import OttWidget from './OttWidget.vue';
import {Components} from 'ottuxlib';
import OttawaStatusList = Components.OttawaStatusList;

declare module 'vue/types/vue' {
    interface Vue {
        target: string;
        location: string;
        zindex: string;
        apiServer: string;
        apiPath: string;
        apiEndpoint: string;
    }
}

/**
 * Ottawa Status List Widget
 */
@Component({ components: { OttWidget, OttawaStatusList } })
export default class OttStatusListWidget extends Vue {
    public widgetComponent = 'OttawaStatusList';
    private server: string = process.env.VUE_APP_OTTAWA_CA_URL || '';
    private path: string = process.env.VUE_APP_STATUS_LIST_PATH || '';
    private endpoint: string | null = null; 

    public mounted() {
          if (typeof this.$root.apiServer !== 'undefined' && this.$root.apiServer !== null) {
              this.server = this.$root.apiServer;
          }
          if (typeof this.$root.apiPath !== 'undefined' && this.$root.apiPath !== null) {
              this.path = this.$root.apiPath;
          }
          if (typeof this.$root.apiEndpoint !== 'undefined' && this.$root.apiEndpoint !== null) {
              this.endpoint = this.$root.apiEndpoint;
          }
    }
}
