










import Vue from 'vue';
import Component from 'vue-class-component';
import OttWidget from './OttWidget.vue';
import {Components} from 'ottuxlib';
import OttawaTopButton = Components.OttawaTopButton;

declare module 'vue/types/vue' {
    interface Vue {
        language: string,
        target: string,
        location: string,
        zindex: string,
    }
}

/**
 * Ottawa Back To Top Widget
 */
@Component({
    components: {
        OttWidget,
        OttawaTopButton
    }
})
export default class OttTopButtonWidget extends Vue {
    public widgetComponent = 'OttawaTopButton';
    private currentLanguage: string | null = null;
    private targetSelector: string = '';
    private buttonLocation: string = 'right';
    private buttonzIndex: string = '1';

    public mounted() {
        if (typeof this.$root.language !== 'undefined' && this.$root.language !== null) {
            this.currentLanguage = this.$root.language;
        }
        if (typeof this.$root.target !== 'undefined' && this.$root.target !== null) {
            this.targetSelector = this.$root.target;
        }
        if (typeof this.$root.location !== 'undefined' && this.$root.location !== null) {
            this.buttonLocation = this.$root.location;
        }
        if (typeof this.$root.zindex !== 'undefined' && this.$root.zindex !== null) {
            this.buttonzIndex = this.$root.zindex;
        }
    }
}
