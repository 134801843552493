



















import Vue from 'vue';
import Component from 'vue-class-component';
import {Components, Utilities} from 'ottuxlib';
import OttWidget from './OttWidget.vue';
import OttawaFooter = Components.OttawaFooter;
import FooterSection = Utilities.FooterSection;

declare module 'vue/types/vue' {
    interface Vue {
        language: string;
        contactMenu: string;
        aboutMenu: string;
        socialMenu: string;
        feedbackMenu: string;
        apiServer: string;
        apiPath: string;
        apiEndpoint: string;
    }
}

/**
 * Ottawa Footer Widget
 * Wraps the Ottawa Footer component in a Ottawa Widget component for shared scope CSS.
 */
@Component({
    components: {
        OttWidget,
        OttawaFooter
    }
})
export default class OttFooterWidget extends Vue {
    public widgetComponent = 'OttawaFooter';
    private currentLanguage: string | null = null;
    private contactSection: FooterSection | null = null;
    private aboutSection: FooterSection | null = null;
    private socialSection: FooterSection | null = null;
    private feedbackSection: FooterSection | null = null;
    private server: string = process.env.VUE_APP_OTTAWA_CA_URL || '';
    private path: string = process.env.VUE_APP_FOOTER_LINKS_PATH || '';
    private endpoint: string | null = null; 

    public created() {
        if (typeof this.$root.apiServer !== 'undefined' && this.$root.apiServer !== null) {
            this.server = this.$root.apiServer;
        }
        if (typeof this.$root.apiPath !== 'undefined' && this.$root.apiPath !== null) {
            this.path = this.$root.apiPath;
        }
        if (typeof this.$root.apiEndpoint !== 'undefined' && this.$root.apiEndpoint !== null) {
            this.endpoint = this.$root.apiEndpoint;
        }
    }
    public mounted() {
        if (typeof this.$root.language !== 'undefined' && this.$root.language !== null) {
            this.currentLanguage = this.$root.language;
        }
        if (typeof this.$root.contactMenu !== 'undefined' && this.$root.contactMenu !== null) {
            this.contactSection = JSON.parse(this.$root.contactMenu);
        }
        if (typeof this.$root.aboutMenu !== 'undefined' && this.$root.aboutMenu !== null) {
            this.aboutSection = JSON.parse(this.$root.aboutMenu);
        }
        if (typeof this.$root.socialMenu !== 'undefined' && this.$root.socialMenu !== null) {
            this.socialSection = JSON.parse(this.$root.socialMenu);
        }
        if (typeof this.$root.feedbackMenu !== 'undefined' && this.$root.feedbackMenu !== null) {
            this.feedbackSection = JSON.parse(this.$root.feedbackMenu);
        }
    }
}
