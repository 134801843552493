































import Vue from 'vue';
import { Component } from 'vue-mixin-decorator';
import OttWidget from './OttWidget.vue';
import {Components, Utilities} from 'ottuxlib';
import OttawaHeader = Components.OttawaHeader;
import MenuLinks = Utilities.MenuLink;

declare module 'vue/types/vue' {
    interface Vue {
        language: string,
        skipToContentId: string;
        searchInputFocusTriggerId: string;
        extraLinks: string;
        searchNameAttr: string;
        searchAction: string;
        languageLink: string;
        menuLabel: string;
        homeLinkUrl: string;
        homeLinkTitle: string;
        searchResultsId: string;
        links: string;
        apiServer: string;
        apiPath: string;
        apiEndpoint: string;
        apiAlertPath: string;
        apiAlertEndpoint: string;
        apiAlertServer: string;
    }
}

/**
 * Ottawa Header Widget
 * Wraps the Ottawa Header component in a Ottawa Widget component for shared scope CSS.
 */
@Component({
    components: {
        OttWidget,
        OttawaHeader
    }
})
export default class OttHeaderWidget extends Vue {
    public widgetComponent = 'OttawaHeader';
    private currentLanguage: string | null = null;
    private menuLinks: MenuLinks[] | null = null;
    private gatewayLinks: MenuLinks[] | null = null
    private searchTarget: string | null = null;
    private searchFieldName: string | null = 'searchfield';
    private searchControls: string | null = '';
    private skipToContent: string | null = 'main-content';
    private searchInputFocusTrigger: string | null = 'search-input-focus-trigger';
    private languageUrl: string | null = null;
    private lgMenuLabel: string | null = null;
    private homeUrl: string | null = null;
    private homeTitle: string | null = null;
    private server: string = process.env.VUE_APP_OTTAWA_CA_URL || '';
    private path: string = process.env.VUE_APP_GATEWAY_MENU_PATH || '';
    private endpoint: string | null = null;
    private alertPath: string = process.env.VUE_APP_SITE_WIDE_ALERTS_PATH || '';
    private alertEndpoint: string | null = null;
    private alertServer: string = process.env.VUE_APP_OTTAWA_CA_URL || '';

    public get getSearchTarget(): string {
        // Use a specific value if configured.
        if (this.searchTarget != null) {
            return this.searchTarget;
        }
        // Otherwise, default to the main site's search for the current language.
        let lang = this.$i18n.locale;
        if (this.currentLanguage != null && this.currentLanguage != '') {
            lang = this.currentLanguage;
        }
        return `https://ottawa.ca/${lang}/search`;
    }

    public created() {
        if (typeof this.$root.apiServer !== 'undefined' && this.$root.apiServer !== null) {
            this.server = this.$root.apiServer;
        }
        if (typeof this.$root.apiPath !== 'undefined' && this.$root.apiPath !== null) {
            this.path = this.$root.apiPath;
        }
        if (typeof this.$root.apiEndpoint !== 'undefined' && this.$root.apiEndpoint !== null) {
            this.endpoint = this.$root.apiEndpoint;
        }
        if (typeof this.$root.apiAlertServer !== 'undefined' && this.$root.apiAlertServer !== null) {
            this.alertServer = this.$root.apiAlertServer;
        }
        if (typeof this.$root.apiAlertPath !== 'undefined' && this.$root.apiAlertPath !== null) {
            this.alertPath = this.$root.apiAlertPath;
        }
        if (typeof this.$root.apiAlertEndpoint !== 'undefined' && this.$root.apiAlertEndpoint !== null) {
            this.alertEndpoint = this.$root.apiAlertEndpoint;
        }
    }

    public mounted() {
        if (typeof this.$root.language !== 'undefined' && this.$root.language !== null) {
            this.currentLanguage = this.$root.language;
        }

        // Set the skip to main content element id.
        if (typeof this.$root.skipToContentId !== 'undefined' && this.$root.skipToContentId !== null) {
            this.skipToContent = this.$root.skipToContentId;
        }
        // Validate the skip to main content element id.
        if (this.skipToContent != null && document && !document.getElementById(this.skipToContent)) {
            console.warn('Could not find the skip to content id.');
        }

        // Set the search input focus trigger element id.
        if (typeof this.$root.searchInputFocusTriggerId !== 'undefined' && this.$root.searchInputFocusTriggerId !== null) {
            this.searchInputFocusTrigger = this.$root.searchInputFocusTriggerId;
        }

        // Check for locally provided Gateways.
        if (typeof this.$root.links !== 'undefined' && this.$root.links !== null) {
            try {
                this.gatewayLinks = JSON.parse(this.$root.links);
            } catch(error) {
                console.error(error.message);
            }
        }

        // Set the extra menu links
        if (typeof this.$root.extraLinks !== 'undefined' && this.$root.extraLinks !== null) {
            try {
                this.menuLinks = JSON.parse(this.$root.extraLinks);
            } catch(error) {
                console.error(error.message);
            }
        }

        // Set the search target
        if (typeof this.$root.searchAction !== 'undefined' && this.$root.searchAction !== null) {
            this.searchTarget = this.$root.searchAction;
        }

        // Set the search field name
        if (typeof this.$root.searchNameAttr !== 'undefined' && this.$root.searchNameAttr !== null) {
            this.searchFieldName = this.$root.searchNameAttr;
        }

        // Set the language link URL.
        if (typeof this.$root.languageLink !== 'undefined' && this.$root.languageLink !== null) {
            this.languageUrl = this.$root.languageLink;
        }

        // Set the menu label.
        if (typeof this.$root.menuLabel !== 'undefined' && this.$root.menuLabel !== null) {
            this.lgMenuLabel = this.$root.menuLabel;
        }

        // Set the home url.
        if (typeof this.$root.homeLinkUrl !== 'undefined' && this.$root.homeLinkUrl !== null) {
            this.homeUrl = this.$root.homeLinkUrl;
        }

        // Set the home title.
        if (typeof this.$root.homeLinkTitle !== 'undefined' && this.$root.homeLinkTitle !== null) {
            this.homeTitle = this.$root.homeLinkTitle;
        }

        // Set the search controls attribute.
        if (typeof this.$root.searchResultsId !== 'undefined' && this.$root.searchResultsId !== null) {
            this.searchControls = this.$root.searchResultsId;
        }


    }
}
